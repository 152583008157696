import React, { useEffect, useState, useContext } from "react";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import WorkerHeader from "./worker_header";
import Skillsbtn from "../common/Skillsbtn";
import { useSelector, useDispatch } from "react-redux";
// Caution: before you start going through, I used icons as pure SVG format thats why the code is a bit bulky, always close SVG bracket to maintain code readability.
import { splitFunc } from "../../utils/pdfUtil/Adjust_gptRes";
import {
  getProfile,
  cv_loading,
  getAllMyCv,
  resume_step_jump_function,
  summaryUpdateCountLoading,
  ActivitiesDetails_loading,
  EducationDetails_loading,
  p_skillsDetails_loading,
  skillsDetails_loading
} from "../../Redux/ActionCreator";
import LoaderModal from "../common/LoaderModal";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { convert } from "../../Redux/convertResume";
import HelmetProvide from "../common/helmet";
import { baseURL } from "../../Redux/baseURL";

// import analytics from "analytics"
import { useAnalytics } from "../../context/AnalyticsContext";
import EmptySection from "./components/empty_section";
import EditButton from "./components/edit_btn";
import CompleteConfirmationGamifiedModal from "../gamified_modals/complete_confirm";
import { AdjustExperienceByDate } from "../../utils/pdfUtil/Adjust_gptRes";
// import PersonalInfoGamifiedModal from "../gamified_modals/personal_info_gamified_modal";
import ExperienceGamifiedModal from "../gamified_modals/experience_gamified_modal";
import EducationGamifiedModal from "../gamified_modals/education_gamified_modal";
import SkillsGamifiedModal from "../gamified_modals/skills_gamified_modal";
import SummaryGamifiedModal from "../gamified_modals/summary_gamified_modal";
import ProfilePicsGamifiedModal from "../gamified_modals/profile_pics_gamified_modal";
import { indexOf } from "lodash";
import QRCodeGenerator from "../QRcode/qrcode";



function WorkerProfile(props) {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const userPics = useSelector((prev) => prev.userInfo);
  const lang_type = useSelector((e) => e.t_lang);
  const [translating, setTranslating] = useState(false);
  const [lan, setLan] = useState(null);
  const savedResume = useSelector((e) => e.saved_cv);
  const res = useSelector((e) => e.last_resume);
  const details = savedResume.message;
  const [picLink, setPicsLink] = useState("");

  const profile = useSelector((prev) => prev.profile);
  const sp_profile = useSelector((e) => e.spanish_profile);
  const dispatch = useDispatch();
  const log_stat = useSelector(e => e.login);
  const stat = log_stat.profile_status.profile_status;
  const [updateText, setUpdateText] = useState("");
  const [urlLink, setUrlLink] = useState("");

  useEffect(() => {
    dispatch(cv_loading(true));
    dispatch(resume_step_jump_function(0));
    dispatch(summaryUpdateCountLoading(true));
    // dispatch(getting_employer_profile_loading(true));
    if (userPics.user !== null) {
      setPicsLink(userPics.user.profile_pics);
    }

    if (savedResume.message === null || savedResume.message.length === 0) {
      dispatch(getAllMyCv(localStorage.getItem("token")));
    }

    // const token = localStorage.getItem('token');
    // dispatch(postUserProfile(token));
    // console.log("Called!!!!!", profile.message);
    let token = localStorage.getItem("token");
    if (
      (profile.isloading === false &&
        (profile.message === undefined || profile.message === null))
    ) {
      // console.log("Was super called here")
      // let token = localStorage.getItem("token");
      dispatch(getProfile(token));
    }
  }, [userPics, profile]);

  useEffect(() => {
		dispatch(p_skillsDetails_loading(true));
		dispatch(skillsDetails_loading(true));
		dispatch(EducationDetails_loading(true));
		dispatch(ActivitiesDetails_loading(true));
	}, []);
  // useEffect(() => {
  //   if (profile.message !== null) {
  //     if (profile.message?.photo == null || profile.message?.photo == undefined) {
  //       navigate("/update/profilephoto");
  //     }
  //   }
  // }, [profile.message]);
  const [experienceModal, setExperienceModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  const [skillsModal, setSkillsModal] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [profilePics, setProfilePics] = useState(false);
  const [hundredModal, sethundredModal] = useState(false);


  useEffect(() => {
    if (profile.message !== null) {
      if (profile.message?.Experience?.length === 0 || !profile.message?.Experience) {
        setTimeout(() => { setExperienceModal(true) }, 20000);
      } else if (profile.message?.Education?.length === 0 || !profile.message?.Education) {
        setTimeout(() => { setEducationModal(true) }, 20000);
      } else if (profile.message?.Skills?.length === 0 || !profile.message?.Skills || profile.message?.Soft_skills?.length === 0 || !profile.message?.Soft_skills || profile.message?.Language?.length === 0 || !profile.message?.Language) {
        setTimeout(() => { setSkillsModal(true) }, 20000);
      } else if (!profile.message?.About) {
        setTimeout(() => { setSummaryModal(true) }, 20000);
      } else if (!profile.message?.photo) {
        setTimeout(() => { setProfilePics(true) }, 20000);
      }
    }
  }, [profile.message]);

  useEffect(() => {
    if (experienceModal) {
      setUrlLink("/user/profile/update/experience");
      setUpdateText("Experience");
    } else if (educationModal) {
      setUrlLink("/user/profile/update/education");
      setUpdateText("Education");
    } else if (skillsModal) {
      setUrlLink("/user/profile/update/skills");
      setUpdateText("Skills/Language");
    } else if (summaryModal) {
      setUrlLink("/user/profile/update/about");
      setUpdateText("Summary");
    } else if (profilePics) {
      setUpdateText("Profile pics");
    }
  }, [experienceModal, educationModal, skillsModal, summaryModal, profilePics]);

  useEffect(() => {
    if (profile.message !== null) {
      if (profile.message?.Experience !== undefined) {
        // console.log("Splitting called");
        profile.message.Experience = splitFunc(profile.message?.Experience);
      }
    }
  }, [profile.message]);

  useEffect(() => {
    // console.log("Printed");
    if (profile.message !== null) {
      // console.log("My call");
      setLan((e) => ({ ...e, ...profile.message }));
    }
  }, [profile.message]);

  // analytics useEffect
  useEffect(() => {
    if (lan !== null) {
      // console.log("after lan anaylytics", analytics);
      analytics.identify("userId", {
        "city": lan?.Address,
        "country": null,
        "postal_code": null,
        "state": null,
        "avatar": lan?.photo,
        "company_name": null,
        "company_id": lan?._id, // profile schema Id
        "created_at": lan?.createdAt,
        "description": lan?.Professional_summary,
        "email": lan?.Email,
        "id": userPics.user?._id, // user schema ID
        "name": lan?.Fullname,
        "phone": lan?.Phone_number,
        "username": lan?.Fullname,
        "website": baseURL
      });
    }
  }, [lan]);

  // console.log("lan", lan);
  // console.log("profile_lan", profile.message);

  const handleResumePreview = () => {
    const resume = details[details.length - 1];
    navigate(
      `/resume/${resume._id}?id=${resume.pdfId}&payment_intent_client_secret=${resume.Secret}&stat=cannot update`
    );
  };

  const experience = experienceModal ? <ExperienceGamifiedModal updateText={updateText} handleClose={() => setExperienceModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const education = educationModal ? <EducationGamifiedModal updateText={updateText} handleClose={() => setEducationModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const skills = skillsModal ? <SkillsGamifiedModal updateText={updateText} handleClose={() => setSkillsModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const summary = summaryModal ? <SummaryGamifiedModal updateText={updateText} handleClose={() => setSummaryModal(false)} handleContinue={() => navigate(urlLink)} /> : null;
  const profile_pics = profilePics ? <ProfilePicsGamifiedModal updateText={updateText} handleClose={() => setProfilePics(false)} handleContinue={() => setProfilePics(false)} /> : null;
  const hundred = hundredModal ? <CompleteConfirmationGamifiedModal updateText={updateText} handleClose={() => sethundredModal(false)} handleContinue={sethundredModal} /> : null;

  return (
    <>
      <HelmetProvide
        title="Moil | Profile"
        description="View and manage your profile on Moil. Showcase your skills and qualifications to recruiters in your field."
        className="notranslate"
      />

      {savedResume.message !== null && profile.message !== null ? (
        <div className="bg-[#FAFAFA] w-full flex justify-center h-calc-70 overflow-y-scroll py-6">
          <div className="w-full px-6 lg:px-0 max-w-[900px] xtraxl:max-w-calculate mx-auto flex gap-y-6 flex-col ">

            <div class="w-full  gap-0 flex flex-col gap-y-4 lg:flex-row lg:gap-10 xtraxl:gap-[54px] justify-between lg:items-center">
              <div>
                <p class="text-[36px] leading-normal text-black text-opacity-90 font-medium text-left">My Profile</p>
                <p class=" text-base font-normal leading-normal text-left text-black text-opacity-90">
                  This is your professional profile, remember to complete it so that employers can know enough about you.
                </p>
              </div>
              <div class="w-full lg:max-w-[300px] p-6 gap-4 justify-between lg:justify-start rounded-[24px] bg-[#E9F1FF] flex items-center">
                <p class=" text-base font-normal leading-normal text-left text-black">
                  Profile Completion
                </p>
                {lan?.CompletionPercentage === 0 ?
                  <div class="profile-header-border text-[24px] w-[84px] h-[84px] font-semibold flex justify-center items-center">
                    {lan?.CompletionPercentage}%
                  </div> :
                  <div className="w-[84px] h-[84px] font-semibold">
                    <CircularProgressbarWithChildren
                      value={lan?.CompletionPercentage}
                      styles={buildStyles({
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'round',
                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,
                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',
                        // Colors
                        pathColor: lan?.CompletionPercentage < 50 ? '#FF6633' : `#5843BD`,

                        trailColor: '#FFFFFF80',
                      })}
                    >
                      <p className="text-[24px] text-[#000000DE]">{lan?.CompletionPercentage}%</p>
                    </CircularProgressbarWithChildren>
                  </div>
                }
              </div>
            </div>

            <div>
              <WorkerHeader data={lan} />
            </div>

               {/* Experience */}

               <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
              <div className="flex justify-between">
                <p class="text-base font-semibold leading-normal text-[#5843BD]">
                  Work Experience
                </p>
                <EditButton handleClick={() => navigate("/user/profile/update/experience")} />
              </div>

              {
                lan?.Experience?.length === 0 && <p class="text-base font-medium leading-normal text-[#FF6633]">
                  Click edit to add your Experience
                </p>
              }


              {lan?.Experience?.map((data, key) => (
                <div
                  className="w-full flex flex-col gap-6 py-4"
                  key={key}
                >
                  {/* up content  */}
                  <div className="flex flex-col tablet:flex-row gap-2 md:gap-8 w-full justify-between">
                    {/* year and kini  */}
                    <ul className=" w-full max-w-[200px]  text-sm">
                      <li className="ml-[0px] font-semibold text-base mb-2">
                        {data?.Work_start_date} - {data?.Work_end_date}
                      </li>
                      <li className="ml-[0px] notranslate">{data?.Where_i_worked}</li>
                      {/* <li>{data.Where_i_worked_location}</li> */}
                    </ul>

                    {/* details side  */}
                    <ul className="w-full text-sm">
                      <li className="ml-[0px] md:ml-0 font-semibold mb-2 text-base">
                        {data?.My_job_title}
                      </li>
                      {Array.isArray(data.Day_to_day_work) == true ? (
                        <>
                          {data.Day_to_day_work?.map((exp) => (
                            <>
                              {exp[0] === "-" || exp[0] == "•" || exp[0] == "," ? (
                                <li className="ml-[12px] md:ml-0 list-disc">{exp.slice(1, exp.length)}</li>
                              ) : (
                                <>
                                {/^\d\./.test(exp) == true ? (
                                    <li className="ml-[12px] md:ml-0 list-disc">{exp.slice(2, exp.length)}</li>
                                  ) : (
                                    <li className="ml-[12px] md:ml-0 list-disc">{exp}</li>
                                  )
                                }
                                </>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <li className="ml-[12px] md:ml-0 list-disc" key={key}>{data?.Day_to_day_work}</li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              ))}
            </div>


            {/* Skills */}

            <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
              <div className="flex justify-between">
                <p class="text-base font-semibold leading-normal text-[#5843BD]">
                  Your skills
                </p>
                <EditButton handleClick={() => navigate("/user/profile/update/skills")} />
              </div>

              {
                lan?.Skills?.length === 0 && <p class="text-base font-medium leading-normal text-[#FF6633]">
                  Click edit to add your technical skills
                </p>
              }

              <div className="flex gap-y-2 gap-x-[6px] w-full flex-wrap">
                <>
                  {lan?.Skills?.map((anything) => (
                    <>
                      {anything?.value !== "" && anything?.value !== undefined ? (
                        <Skillsbtn
                          btntxt={anything?.value}
                          key={lan?.Skills?.indexOf(anything)}
                        />
                      ) : (
                        <>{typeof anything !== "string" || (typeof anything == "string" && anything == "") ? null : 
                          (<Skillsbtn
                          btntxt={anything}
                          key={lan?.Skills?.indexOf(anything)}
                        />)
                        }</>
                      )}
                    </>
                  ))}
                </>
              </div>
            </div>

                      {/* Soft Skills */}

                      <div className="pb-6 w-full">
              <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
                <div className="flex justify-between">
                  <p class="text-base font-semibold leading-normal text-[#5843BD]">
                    Soft skills
                  </p>
                  <EditButton handleClick={() => navigate("/user/profile/update/skills")} />
                </div>

                {
                  lan?.Soft_skills?.length === 0 && <p class="text-base font-medium leading-normal text-[#FF6633]">
                    Click edit to add your Soft skills
                  </p>
                }

                <div className="flex gap-y-2 gap-x-[6px] w-full flex-wrap">
                  <>
                    {lan?.Soft_skills?.map((anything) => (
                      <>
                        {anything?.value !== "" && anything?.value !== undefined ? (
                          <Skillsbtn
                            btntxt={anything?.value}
                            key={lan?.Soft_skills?.indexOf(anything)}
                          />
                        ) : (
                          <>{typeof anything !== "string" || (typeof anything == "string" && anything == "") ? null : 
                            (<Skillsbtn
                            btntxt={anything}
                            key={lan?.Soft_skills?.indexOf(anything)}
                          />)
                          }</>
                        )}
                      </>
                    ))}
                  </>
                </div>
              </div>
            </div>



            {/* Language */}

            <div className="pb-6 w-full">
              <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
                <div className="flex justify-between">
                  <p class="text-base font-semibold leading-normal text-[#5843BD]">
                    Language
                  </p>
                  <EditButton handleClick={() => navigate("/user/profile/update/skills")} />
                </div>

                {
                  lan?.Language?.length === 0 && <p class="text-base font-medium leading-normal text-[#FF6633]">
                    Click edit to add your Languages
                  </p>
                }

                <div className="flex gap-y-2 gap-x-[6px] w-full flex-wrap">
                  <>
                    {lan?.Language?.map((lang) => (
                      <div className="w-full max-w-[327px] px-4 py-3 border border-[#D9D9D9] shadow rounded-[8px] bg-[#5843BD1A] flex-col flex gap-y-1">
                        <p className="text-[#1F1F1F] font-semibold">{lang.language}</p>
                        <p className="text-[#22263ACC]">{lang.rate}</p>
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>


            {/* Educatiom */}

            <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
              <div className="flex justify-between">
                <p class="text-base font-semibold leading-normal text-[#5843BD]">
                  Education
                </p>
                <EditButton handleClick={() => navigate("/user/profile/update/education")} />
              </div>

              {
                lan?.Education?.length === 0 && <p class="text-base font-medium leading-normal text-[#FF6633]">
                  Click edit to add your Education
                </p>
              }


              {lan?.Education?.map((edu, key) => (
                <div
                  className="w-full flex flex-col gap-6 py-3"
                  key={key}
                >
                  {/* up content  */}
                  <div className="flex flex-col tablet:flex-row gap-4 md:gap-8 w-full justify-between">
                    {/* year and kini  */}
                    <ul className="w-full max-w-[200px] text-sm">
                      <li className="ml-[0px] font-semibold text-base">
                        {edu?.End_date}
                      </li>
                    </ul>

                    {/* details side  */}
                    <ul className=" w-full list-disc text-sm">
                      {edu?.Degree ? <li className="ml-[12px] md:ml-0 font-semibold text-base mb-2">
                        {edu?.Degree}
                      </li> : null}
                      {edu?.Course !== "N/A" && edu?.Course !== undefined && edu?.Course !== null ? 
                        <li className="ml-[12px] md:ml-0">
                          {edu?.Course}
                        </li> : null
                      }
                      {edu?.School ?
                        <li className="ml-[12px] md:ml-0 notranslate">{edu?.School}</li> : null}
                    </ul>
                  </div>
                </div>
              ))}
            </div>


            <EmptySection heading="Professional summary" subheading="Click edit to add your professional summary" data={lan} />

            <div className="pb-6 w-full">
              <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
                <div className="flex justify-between">
                  <p class="text-base font-semibold leading-normal text-[#5843BD]">
                    QR code
                  </p>
                  <EditButton handleClick={() => navigate("/user/profile/update/skills")} />
                </div>

                <div className="flex gap-y-2 gap-x-[6px] w-full flex-wrap">
                  <>
                  {userPics?.user.profile_id && (
                    <QRCodeGenerator profileId={userPics?.user.profile_id} name={profile.message !== null ? profile.message?.Fullname : userPics?.user?.name}/>
                  )}
                  </>
                </div>
                <div>
                  <h3 class="text-base font-semibold leading-normal text-[#5843BD] cursor-pointer" onClick={() => navigate(`/moilQR/${userPics?.user.profile_id}`)}>View code</h3>
                </div>
              </div>
            </div>

            <Outlet />
          </div>
        </div>
      ) : (
        <div>
          <LoaderModal text="Fetching profile" />
        </div>
      )}
      {experience}
      {education}
      {skills}
      {summary}
      {profile_pics}
      {hundred}
    </>
  );
}

export default WorkerProfile;
