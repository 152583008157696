import { createPortal } from "react-dom";
import { GamefiedVector } from "../../Resume/svgs/svgs";
import { useNavigate, Link } from "react-router-dom";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { useSelector } from "react-redux";

export default function SummaryCompleteGamifiedModal(props) {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);

  let urlLink;
  let updateText;

  if (window.location.pathname === "/user/profile/update/about") {
    if (profile.message?.Experience?.length === 0 || !profile.message?.Experience) {
      urlLink = "/user/profile/update/experience";
      updateText = "Update Experience";
    } else if (profile.message?.Education?.length === 0 || !profile.message?.Education) {
      urlLink = "/user/profile/update/education";
      updateText = "Update Education";
    } else if (profile.message?.Skills?.length === 0 || !profile.message?.Skills) {
      urlLink = "/user/profile/update/skills";
      updateText = "Update Skills/Language";
    } else {
      urlLink = null;
      updateText = null;
    }
  }

  const handleClick = () => {
    if (window.location.pathname === "/user/profile/update/about") {
      navigate(urlLink);
    }
  }


  return (
    <div>
      {
        createPortal(
          <div className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
            <section className="bg-white md:max-w-[550px] sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
              <div className="w-full max-w-[660px] p-4 flex justify-between items-center bg-[#5843BD] border-b-8 border-[#E9F1FF] rounded-tr-[24px] rounded-tl-[24px] ">
                <p className=" text-lg font-medium leading-normal text-left text-white">
                  Profile Completion
                </p>
                <div className="w-[64px] h-[64px] bg-[#F5F9FF] rounded-[50%] font-semibold">
                  <CircularProgressbarWithChildren
                    value={profile.message?.CompletionPercentage}
                    styles={buildStyles({
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'round',
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',
                      // Colors
                      pathColor: profile.message?.CompletionPercentage < 50 ? '#FF6633' : `#5843BD`,

                      trailColor: '#FFFFFF80',
                    })}
                  >
                    <p className="text-[20px] text-[#000000DE]">{profile.message?.CompletionPercentage}%</p>
                  </CircularProgressbarWithChildren>
                </div>
              </div>

              <div className="absolute top-[-2%] -z-1 left-[-20%]">
                <GamefiedVector />
              </div>

              <div className="flex flex-col items-center relative z-[1000] justify-center gap-y-4">
                <div className="w-full">
                  <div className="px-4 py-2 w-full gap-x-4 flex justify-between items-center">
                    <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717676522/confetti.png" className="w-[70px] md:w-[90px]" />
                    <div className="incomplete-profile-image w-[64px] h-[64px] font-semibold flex justify-center items-center">
                      <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717677314/fourty_memoji.png" className="w-full rounded-[50%]" />
                    </div>
                    <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1717676522/confetti.png" className="w-[70px] md:w-[90px]" />
                  </div>

                  <p className="text-[32px] font-semibold leading-none text-center text-[#5843BD]">
                    {profile.message?.CompletionPercentage}% is no joke,<br />
                    Well Done!
                  </p>
                </div>


                <p className="text-base font-semibold rounded-[4px] leading-tight p-2 w-max text-center text-black bg-[#FF66331A]">
                  Your profile is <span className="text-[#FF6633]">{profile.message?.CompletionPercentage}% complete</span>
                </p>

                <p className=" text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
                  View your profile now or <span className="text-[#5843BD]">upload your profile</span> <br className="hidden md:block" /><span className="text-[#5843BD]">picture</span> right away.
                </p>

                <div className="flex justify-between items-center justify-center flex-col gap-x-4  sm:flex-row gap-y-4">

                  {!updateText && !urlLink ? null : <button class="w-full md:w-max items-center justify-center flex gap-x-2 border border-[#FF6633] px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center text-[#FF6633] cursor-pointer" onClick={handleClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 17V7" stroke="#FF6633" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M7 12L17 12" stroke="#FF6633" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    {updateText}
                  </button>}

                  {/* {
                    profile.message?.CompletionPercentage === 95 && (
                      <button class="w-full md:w-max items-center justify-center flex gap-x-2 border border-[#FF6633] px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center text-[#FF6633] cursor-pointer" onClick={() => navigate("/update/profilephoto")}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 17V7" stroke="#FF6633" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M7 12L17 12" stroke="#FF6633" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                      Upload Profile Picture
                    </button>
                    )
                  } */}
                </div>
                <Link to={{ pathname: `${props.profileLink}` }} className=" text-sm font-normal py-4 leading-[1.2] text-center text-[#5843BD33]">
                  Remind me later
                </Link>


              </div>

            </section>
          </div>, document.getElementById("modal")
        )
      }
    </div>
  )
}