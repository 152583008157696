import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import Tab from "../tab/tab";
import TopBar from "../../topbar/Top-Bar";
import SideBar from "../../sidebar/sidebar";
import TextInput from "../text_input/text_input";
import $ from "jquery";
import {
  personal_information,
  save_personal_details,
  save_secret,
  generate_my_cv,
  updatePersonalInfo,
  sendOtp,
  personal_loading,
  resume_step_jump_function,
  returnHome,
  send_otp_loading,
  existing_resume,

} from "../../../Redux/ActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { createPortal } from "react-dom";
import PhoneVerificatioModal from "../../common/phone_verification_modal";
import countryData from 'country-data';
import CustomSelect from "../../common/custom_select";
import { saveIntoServerCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import SelectLanguage from "../../common/resumeLangSelection/selectLanguage";
import BackButton from "../../common/back_button";
import { baseURL } from "../../../Redux/baseURL";
// import TwentyPercentGamifiedModal from "../../gamified_modals/twenty_gamified_modal";
import LoaderModal from "../../common/LoaderModal";

export default function UserPreference(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const info = useSelector((info) => info.personal_info);
  const generated = useSelector((e) => e.generateCV);
  const profile = useSelector((e) => e.login);
  const userInfo = useSelector((e) => e.userInfo);
  let stat = profile.profile_status.profile_status;
  const cv = useSelector((prev) => prev.cvId);
  const secret = useSelector((prev) => prev.secret);
  const sidebar = useSelector(e => e.sidebarOpen.message);
  const p_profile = useSelector((e) => e.profile);
  const [defaults, setDefaults] = useState(false);
  const [obj, setObj] = useState();
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeCountry, setCountryCodeCountry] = useState("");
  const [verifyModal, setVerifyModal] = useState(false);
  const [resendTimed, setResendTimed] = useState(false);
  const send_otp = useSelector((state) => state.send_otp);
  const verify_otp = useSelector(state => state.otp);
  const phoneInputRef = useRef();
  const countrCodeRef = useRef();
  const otp = useSelector((state) => state.otp);
  const resumeStep = useSelector(e => e.resumeStep);
  const existingResume = useSelector(e => e.existingCV);
  const [saving, setSaving] = useState(false);

  const searchParam = new URLSearchParams(window.location.search);
  const process = searchParam.get("create profile");

  // get the id parameter if any
  const cv_id = useSelector((prev) => prev.cvId);
  let id = cv_id.id;

  let [inputValue, setInputValue] = useState(countryCode);

  const handleInputChange = (e) => {
    if (window.location.pathname === "/user/profile/update/personalInfo" &&
      p_profile.message !== null && p_profile.message?.CompletionPercentage >= 95) {
        setInputValue(p_profile.message?.Phone_number);
    } else {
      localStorage.setItem("phone_number", e.target.value);
      let newValue = e.target.value;
      if (newValue.includes(countryCode)) {
        setInputValue(newValue);
      } else {
        setInputValue(countryCode);
      }
    }
  }

  // get te country codes data
  const countryCodes = countryData.callingCountries.all.map((country) => ({
    code: `${country.countryCallingCodes[0]}`,
    alpha2: country.alpha2,
    name: country.name
  }));

  const [twentyModal, setTwentymodal] = useState(false);

  // useEffect(() => {
  //   if (existingResume == null) {
  //     setTimeout(() => {setTwentymodal(true)}, 100);
  //   }
  // }, [p_profile.message]);

  useEffect(() => {
    // console.log(info);
    if (info?.message !== null || info?.message?.Phone_number?.includes('+')) {
      let country_code = getOneFromServerCache("country_code");
      let country_code_country = getOneFromServerCache("country_code_country");

      country_code.then(resp => resp.json()).then(res => {
        if (res.success == true) {
          if (res.data !== null && res.data !== undefined) {
            let response = JSON.parse(res.data);
            // console.log("respObj", response);
            setCountryCode(response);
          }
        } else if (res.success === false && res.message == "Unauthorized") {
          dispatch(returnHome(res.status));
        }
      })

      country_code_country.then(resp => resp.json()).then(res => {
        if (res.success === true) {
          if (res.data !== null && res.data !== undefined) {
            let response = JSON.parse(res.data);
            // console.log("respObj", response);
            setCountryCodeCountry(response);
          }
        } else if (res.success === false && res.message == "Unauthorized") {
          dispatch(returnHome(res.status));
        }
      })
    }
    else {
      setCountryCode("+1");
      setCountryCodeCountry("United States");
    }
  }, [info])

  useEffect(() => {
    AddCountryCode(countryCode);
  }, [countryCode])

  const [showLanguageModal, setShowLanguageModal] = useState(false);
  useEffect(() => {
    if (process == null && window.location.pathname !== "/form") {
      if (window.location.pathname !== "/user/profile/update/personalInfo") {
        if (searchParam.has("template")) {
          setShowLanguageModal(true);
        }
        // let Language = getOneFromServerCache("Language");
        // Language.then(resp => resp.json())
        //   .then(resp => {
        //     // console.log("Language", resp.data);
        //     if (resp.success == true) {
        //       if (resp.data == null) {
        //         setShowLanguageModal(true)
        //       } else {
        //         setShowLanguageModal(false);
        //       }
        //     } else if (resp.success === false && resp.message == "Unauthorized") {
        //       dispatch(returnHome(resp.status));
        //     }
        //   }).catch(err => {
        //     // console.log("Language getting error", err);
        //   });
      }
    }

    let cacheInfo = getOneFromServerCache("personalInfo")
    cacheInfo.then(res => res.json())
      .then(res => {
        // console.log("server cache value", res);
        if (res.success === true) {
          if (res.data !== null && res.data !== undefined) {
            let resObj = JSON.parse(res.data);
            // console.log("respObj", resObj);
            dispatch(personal_information(resObj));
          }
          // console.log("Done");
        } else if (res.success === false && res.message == "Unauthorized") {
          dispatch(returnHome(res.status));
        }
      }).catch(err => {
        // console.log("Error getting personal info", err)
      })

    // if (resumeStep == 0) {
    //   // get the last key here.
    //   let cacheStep = getOneFromServerCache("step");
    //   cacheStep.then(res => res.json())
    //     .then(res => {
    //       if (res.success === true) {
    //         let resObj = JSON.parse(res.data);
    //         // console.log("resObj type and step", typeof resObj, resObj);
    //         if (resObj > 1) {
    //           // dispatch(resume_step_jump_function(resObj));
    //           // console.log("I was supposed to be called!.")
    //         }
    //       } else if (res.success === false && res.message == "Unauthorized") {
    //         dispatch(returnHome(res.status));
    //       }
    //     }).catch(err => // console.log("Error getting step", err));
    // }

  }, []);



  useEffect(() => {
    if (secret.secret === null && (existingResume.message !== null && existingResume.message?.Secret == null)) {
      const clientSecret = uuidv4();
      dispatch(save_secret(clientSecret));
    } else {
      dispatch(save_secret(existingResume.message?.Secret))
    }
  }, []);

  const [go, setGo] = useState(false);
  useEffect(() => {
    if (p_profile.message == null || (p_profile.message?.CompletionPercentage !== undefined && p_profile.message?.CompletionPercentage < 95)) {
      if (info.message !== null) {
        if (go == true) {
          if (window.location.pathname == "/user/profile/update/personalInfo" && p_profile.message !== null && p_profile.message?.CompletionPercentage < 95) {
            navigate(`/user/profile/:${userInfo.user.profile_id}`);
          } else {
            props.handleStepChange();
          }
          setGo(false);
        }
      }
    }
    if (info.errMess !== null) {
      setTimeout(() => {
        toast.error(
          info.errMess,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }, 50);
      dispatch(personal_loading(true));
      setSaving(false);
    }
  }, [info]);

  useEffect(() => {
    // console.log(info);
    if (info.message !== null) {
      // console.log(info?.message?.Phone_number);
      $('[name="firstname"]').val(info.message.Firstname);
      $('[name="lastname"]').val(info.message.LastName);
      $('[name="address"]').val(info.message.Address);
      $('[name="email"]').val(info.message.Email);
      $('[name="phone_number"]').val(info.message.Phone_number);
      if (info.message?.jobInterest !== null && info.message?.jobInterest !== undefined) {
        $('[name="i"]').val(info.message?.jobInterest[0]);
        $('[name="j"]').val(info.message?.jobInterest[1]);
        $('[name="k"]').val(info.message?.jobInterest[2]);
      }
    } else {
      if (localStorage.getItem("phone_number") !== null) {
        $('[name="website"]').val(localStorage.getItem("phone_number"));
      }

      if (userInfo.user !== null) {
        // prefill the email space
        $('[name="email"]').val(userInfo.user?.email);
      }
    }
  }, [info.message]);

  useEffect(() => {
    if (
      window.location.pathname === "/user/profile/update/personalInfo" &&
      p_profile.message !== null && p_profile.message?.CompletionPercentage >= 95
    ) {
      setDefaults(true);
      setDisable(true);
      $('[name="firstname"]').val(p_profile.message?.FirstName);
      $('[name="lastname"]').val(p_profile.message?.LastName);
      $('[name="address"]').val(p_profile.message?.Address);
      $('[name="email"]').val(p_profile.message?.Email);
      // $('[name="phone_number"]').val(p_profile.message?.Phone_number);
      setInputValue(p_profile.message?.Phone_number);
      if (p_profile.message.JobInterests !== null && p_profile.message?.JobInterests !== undefined) {
        $('[name="i"]').val(p_profile.message?.JobInterests[0]?.value);
        localStorage.setItem("i", p_profile.message?.JobInterests[0]?.value);
        $('[name="j"]').val(p_profile.message?.JobInterests[1]?.value);
        localStorage.setItem("j", p_profile.message?.JobInterests[1]?.value);
        $('[name="k"]').val(p_profile.message?.JobInterests[2]?.value);
        localStorage.setItem("k", p_profile.message?.JobInterests[2]?.value);
      }
    } else if (window.location.pathname === `/form/${cv.id}/update`) {
      $('[name="firstname"]').val(generated.message.Fullname);
      $('[name="lastname"]').val(generated.message.LastName);
      $('[name="address"]').val(generated.message.Address);
      $('[name="email"]').val(generated.message.Email);
      $('[name="phone_number"]').val(generated.message.Phone_number);
      if (generated.message.JobInterests !== null && generated.message?.JobInterests !== undefined) {
        $('[name="i"]').val(generated.message?.JobInterests[0].value);
        $('[name="j"]').val(generated.message?.JobInterests[1].value);
        $('[name="k"]').val(generated.message?.JobInterests[2].value);
      }
    } else {
      // let get the temp details into the existing cv, if the person have.
      if (existingResume.message === null) {
        const getExistingCv = async () => {
          return fetch(baseURL + "/api/temp/getTempRes", {
            headers: {
              // "Authorization": `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
            credentials: "include"
          }).then(resp => resp.json())
            .then(resp => {
              if (resp.success === true) {
                // console.log("Temp response", resp);
                if (resp.data !== null) {
                  dispatch(existing_resume(resp.data));
                  dispatch(personal_information({
                    Firstname: resp.data?.Fullname?.split(" ")[0],
                    LastName: resp.data?.Fullname?.split(" ")[1],
                    Address: resp.data?.Address,
                    Email: resp.data?.Email || userInfo.user.email,
                    Phone_number: resp.data?.Phone_number,
                    jobInterest: resp.data?.jobInterest
                  }))
                  dispatch(save_secret(resp.data?.Secret))
                }
              } else if (resp.success === false && resp.message == "Unauthorized") {
                dispatch(returnHome(resp.status));
              }

            }).catch(err => {
              // console.log("Error getting temp resume", err)
            })
        }
        getExistingCv();
      }
    }
  }, []);

  useEffect(() => {
    if (obj !== undefined) {
      dispatch(generate_my_cv(obj));
    }

    if (send_otp.errMess !== null) {
      setTimeout(() => {
        toast.error(send_otp.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    }
  }, [obj, send_otp.errMess]);

  const [disable, setDisable] = useState(false);

  const handleSaveAndContinue = async (flag) => {
    setSaving(true);
    event.preventDefault();
    // const data = new FormData(event.target);
    // let final_num = data.get("phone_number");
    let interest1 = localStorage.getItem("i");
    let interest2 = localStorage.getItem("j");
    let interest3 = localStorage.getItem("k");
    if (countryCode && inputValue.includes("+")) {
      const personal_data = {
        // Firstname: data.get("firstname"),
        // LastName: data.get("lastname"),
        // Address: data.get("address"),
        // Email: data.get("email"),
        // Phone_number: final_num,
        Firstname: $('[name="firstname"]').val(),
        LastName: $('[name="lastname"]').val(),
        Address: $('[name="address"]').val(),
        Email: $('[name="email"]').val(),
        Phone_number: $('[name="phone_number"]').val(),
        Secret: secret.secret,
        jobInterest: [interest1, interest2, interest3]
      };
      if (window.location.pathname === "/user/profile/update/personalInfo" && p_profile.message !== null && p_profile.message?.CompletionPercentage >= 95) {
        // update
        dispatch(updatePersonalInfo(personal_data));
        navigate(`/user/profile/:${userInfo.user?.profile_id}`);
      } else if (window.location.pathname === `/form/${cv.id}/update`) {
        let generated_value = generated.message !== null ? generated.message : {};
        generated_value.Fullname =
          personal_data.Firstname + " " + personal_data.LastName;
        generated_value.Address = personal_data.Address;
        generated_value.Email = personal_data.Email;
        generated_value.Phone_number = personal_data.Phone_number;
        generated_value.Linkedin = personal_data.Linkedin;
        generated_value.Website = personal_data.Website;
        // update the info as well, the extracted value from the resume
        // info = info !== null ? info : {};
        // info.message.Firstname = personal_data?.Firstname;
        // info.message.LastName = personal_data?.LastName;
        // info.message.Address = personal_data?.Address;
        // info.message.Email = personal_data?.Email;
        // info.message.Phone_number = personal_data?.Phone_number;
        // info.message.Linkedin = personal_data?.Linkedin;
        // info.message.Website = personal_data?.Website;
        setObj(generated_value);
        dispatch(personal_information(personal_data));
        dispatch(save_personal_details(personal_data, id, secret.secret));
        navigate("/allCv");
      } else {
        if ((process !== null || (p_profile.message !== null && p_profile.message?.CompletionPercentage < 95) || p_profile.message === null) && localStorage.getItem("i") == null) {
          setTimeout(() => {
            toast.error("Please add at least one job interest", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
          setSaving(false);
        } else {
          if (
            personal_data.Firstname == "" ||
            personal_data.LastName == "" ||
            personal_data.Address == "" ||
            personal_data.Phone_number === "" ||
            personal_data.Phone_number.length < 10 ||
            personal_data.Email === ""
          ) {
            let text;
            if (personal_data.Firstname == "") {
              text = "Firstname is required";
            } else if (personal_data.LastName == "") {
              text = "Lastname is required";
            } else if (personal_data.Address == "") {
              text = "Address is required";
            } else if (personal_data.Phone_number == "") {
              text = "Phone number is required";
            } else if (personal_data.Phone_number.length < 10) {
              text = "Please make sure your phone number is at least 10 digit."
            } else if (personal_data.Email == "") {
              text = "Email is required";
            }
            setTimeout(() => {
              toast.error(text, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
            setSaving(false);
          } else {
            if (process !== null) {
              personal_data.method = "Manual";
            } else {
              personal_data.method = "Upload";
            }
            // also post to the db
            if (p_profile.message == null || (p_profile.message?.CompletionPercentage !== undefined && p_profile.message?.CompletionPercentage < 95)) {
              setGo(true);
              dispatch(save_personal_details(personal_data, id, secret.secret));
              // save into server cache
              await saveIntoServerCache("personalInfo", personal_data);
            } else {
              // console.log("Personal data", personal_data);
              dispatch(personal_information(personal_data));
              // save into server cache
              await saveIntoServerCache("personalInfo", personal_data);
              if (searchParam.has("template")) {
                searchParam.delete("template");
                // Update the URL without reloading the page
                let newUrl = `${window.location.pathname}`;
                window.history.replaceState({}, document.title, newUrl);
              }
              props.handleStepChange();
            }
          }
        }
      }
    } else {
      setTimeout(() => {
        toast.error("Please add your country code to your phone number e.g +1, +2, +45", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      setSaving(false);
    }
  };


  useEffect(() => {
    setSaving(false);
  }, [p_profile.message])

  const handleSendVerification = (e) => {
    e.preventDefault();
    const final_num = inputValue;
    if (final_num.includes("+")) {
      resendTimed === false && setResendTimed(true);
      verifyModal === false && setVerifyModal(true);
      // console.log(final_num);
      dispatch(sendOtp(String(final_num)));

      if (send_otp.errMess !== null) {
        setTimeout(() => {
          toast.error(send_otp.errMess, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);

        dispatch(send_otp_loading());
      }
    } else {
      setTimeout(() => {
        toast.error("Please add your country code e.g +1, +2, +45", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    }
  };

  // function to direct user to the last page
  const goBack = () => {
    // Check if the browser supports the window.history.go(-1) method
    if (window.history.length > 1) {
      // Go back to the previous page
      window.history.go(-1);
    } else {
      // If the history stack is empty or has only one entry,
      // redirect the user to a default page (e.g., the home page)
      window.location.href = "/user/dashboard";
    }
  }

  const AddCountryCode = (code) => {
    if (window.location.pathname === "/user/profile/update/personalInfo" &&
    p_profile.message !== null && p_profile.message?.CompletionPercentage >= 95) {
      setInputValue(p_profile.message?.Phone_number);
      inputValue = p_profile.message?.Phone_number;
    } else {
      setInputValue(code);
      inputValue = code;
    }
  }

  const handleSelectCountryCode = (code) => {
    setCountryCode(code);
    setInputValue(code);
  }

  const hanldeSetCountryCodeCountry = (country) => {
    setCountryCodeCountry(country);
  }

  // const twenty = twentyModal ? <TwentyPercentGamifiedModal /> : null;

  const verificationModal =
    send_otp.errMess === null && verifyModal && verify_otp.message === null
      ? createPortal(
        <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
          <PhoneVerificatioModal
            resendCode={() => resendTimed === false && setResendTimed(true)}
            waitingEnded={() => resendTimed && setResendTimed(false)}
            resendTimed={resendTimed}
            phoneNumber={inputValue}
            removeModal={() => verifyModal === true && setVerifyModal(false)}
          />
        </div>,
        document.getElementById("modal")
      )
      : null;

  // console.log("showLanguageModal", showLanguageModal)
  let showModal = showLanguageModal ? createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center md:bg-black md:bg-opacity-70 backdrop-blur-[2px] sm:bg-white xsm:bg-white">
      <SelectLanguage handleClick={() => setShowLanguageModal(false)} back="/generateCV" />
    </div>, document.getElementById("Language")
  ) : null;

  const savingloader = saving ? <LoaderModal text="Saving your information" /> : null;

  // console.log("inputValue", inputValue);
  // console.log("phone number", p_profile.message?.Phone_number);

  return (
    <>
      <Helmet>
        <title>Moil | Create Your Resume</title>
        <meta
          name="description"
          content="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
          className="notranslate"
        />
        <meta property="og:title" content="Moil | Create Your Resume" />
        <meta
          property="og:description"
          content="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/daudj5isi/image/upload/v1696694006/images/Moil%20Company.png"
        />
      </Helmet>

      <div className={`grid grid-cols-auto md:grid-cols-smdash ${sidebar ? 'cont:grid-cols-dash' : ''} grid-rows-dash`}>
        <TopBar topBarShowMenu={true} />
        <SideBar />
        <div className="bg-[#FAFAFA] px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center">
          <div className="w-full xtraxl:max-w-calculate flex flex-col items-center">
            {/* <div className="mb-3">
              <BackButton onClick={goBack} />
            </div> */}
            <div className="max-w-[900px] xtraxl:max-w-calculate w-full">
              <p class=" text-[24px] font-semibold leading-9 text-left text-black text-opacity-87">
                Complete your profile
              </p>

              <p class="text-base font-normal leading-6 text-left text-black text-opacity-87">
                Fill in your basic details in the form below to complete your profile.
              </p>
            </div>


            <div className="max-w-[900px] xtraxl:max-w-calculate py-5 w-full bg-white bg-opacity-80 flex-col justify-start items-start gap-5 inline-flex">
              <div className="tabarea justify-start lg:justify-between items-center gap-6 inline-flex w-full flex-shrink-0 max-w-[calc(100vw-88px)] overflow-hidden">
                <Tab
                  // label="Personal Information"
                  label="Personal Information"
                  current={true}
                  completed={false}
                  num="1"
                />
                <Tab num="2" current={false} label="Experience" completed={false} />
                <Tab num="3" current={false} label="Education" completed={false} />
                <Tab num="4" current={false} label="Skill/Language" completed={false} />
                <Tab num="5" current={false} label="Professional Summary" completed={false} />
              </div>

              <div className="bg-white py-6 px-4 flex flex-col gap-4 rounded-[16px] resume_page w-full">

                <p class="text-xl font-semibold leading-8 text-left text-[#5843BD]">
                  Personal Information
                </p>

                <form
                  className="flex-col justify-start items-start gap-12 flex w-full"
                >
                  <div className="flex-col justify-start items-start gap-5 flex w-full">

                    {/* section */}
                    <div className="flex flex-col md:flex-row gap-y-4  items-center w-full gap-x-8">
                      <div className="flex-col justify-start items-start gap-2 flex w-full">
                        <div className="flex gap-3 items-center">
                          <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                          <label
                            className="text-black text-base font-medium"
                            for="firstName"
                          >
                            What’s your first name?
                          </label>
                        </div>
                        <TextInput
                          placeholder="First Name here"
                          id="firstName"
                          name="firstname"
                          disabled={defaults}
                        />
                      </div>
                      <div className="flex-col justify-start items-start gap-2 flex w-full">
                        <div className="flex gap-3 items-center">
                          <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                          <label
                            className="text-black text-base font-medium"
                            for="lastName"
                          >
                            What’s your last name?
                          </label>
                        </div>
                        <TextInput
                          placeholder="Last Name here"
                          id="lastName"
                          name="lastname"
                          onChange={(event) =>
                            localStorage.setItem("lastname", event.target.value)
                          }
                        />
                      </div>
                    </div>

                    {/* section */}
                    <div className="flex flex-col md:flex-row items-center w-full gap-y-8 gap-x-8">
                      <div className="flex-col justify-start items-start gap-2 flex w-full">
                        <div className="flex gap-3 items-center">
                          <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                          <label
                            className="text-black text-base font-medium"
                            for="address"
                          >
                            What is your location?
                          </label>
                        </div>
                        <input
                          type="text"
                          className="py-2 px-4 w-full rounded-lg placeholder:text-[#0000004D] border border-indigo-400 border-opacity-30 focus:ring-indigo-400 focus:border-indigo-400"
                          placeholder="City, state"
                          id="address"
                          name="address"
                        />
                      </div>
                      <div className="flex-col justify-start items-start gap-2 flex w-full">
                        <div className="flex gap-3 items-center">
                          <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                          <label
                            className="text-black text-base font-medium"
                            for="email"
                          >
                            What’s your email address?
                          </label>
                        </div>
                        <input
                          type="email"
                          className="py-2 px-4 w-full rounded-lg placeholder:text-[#0000004D] border border-indigo-400 border-opacity-30 focus:ring-indigo-400 focus:border-indigo-400"
                          placeholder="Email address here"
                          id="email"
                          name="email"
                          disabled={disable}
                        />
                      </div>
                    </div>

                    {/* section */}
                    <div className="flex-col justify-start items-start gap-2 flex w-full">
                      <div className="flex gap-3 items-center">
                        <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                        <label
                          className="text-black text-base font-medium"
                          for="phoneNumber"
                        >
                          What is your phone number?
                        </label>
                      </div>

                      <p class="text-base font-normal leading-6 italic text-[#B1B0B0]">
                        Click on “Verify Phone” to verify your phone number
                      </p>

                      <div className="w-full flex-col md:flex-row gap-y-2 flex gap-x-3">
                        <div className="w-full flex gap-3">
                          {(userInfo.user?.phone_number_verified != true && window.location.pathname != "/user/profile/update/personalInfo") && <CustomSelect countryCodeCountry={countryCodeCountry}
                            hanldeSetCountryCodeCountry={hanldeSetCountryCodeCountry} handleSelectCountryCode={handleSelectCountryCode}
                            countryCodes={countryCodes} countryCode={countryCode} AddCountryCode={AddCountryCode} />}
                          <input
                            type="string"
                            className="py-2 px-4 w-full rounded-lg border border-indigo-400 border-opacity-30"
                            placeholder="+123 456 7890"
                            id="phoneNumber"
                            name="phone_number"
                            value={(userInfo.user?.phone_number_verified == true && window.location.pathname != "/user/profile/update/personalInfo") ? userInfo.user?.phone_number : inputValue}
                            onChange={handleInputChange}
                            disabled={disable}
                          // ref={phoneInputRef}
                          />
                        </div>

                        {userInfo.user?.phone_number_verified == true || (countryCode !== "+1") || p_profile.message !== null ? null :
                          <div
                            className="px-4 py-2 bg-orange-500 rounded-[4px] shadow justify-center items-center inline-flex cursor-pointer"
                            onClick={handleSendVerification}
                          >
                            <span className="text-center text-white text-sm sm:text-base font-normal whitespace-nowrap">
                              Verify Phone
                            </span>
                          </div>
                        }

                      </div>

                      {/* Warning bar */}

                      {p_profile.message === null && (countryCode == "+1") ?
                        <>
                          <div className="w-full bg-orange-500 justify-start items-start gap-2 inline-flex">
                            <div></div>
                            <div className="grow shrink basis-0 pl-3.5 pr-4 py-2 bg-[#FAF3D1]  justify-start items-start gap-3.5 flex">
                              {userInfo.user?.phone_number_verified == true ? null :
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z"
                                    fill="#FF6633"
                                  />
                                </svg>
                              }

                              <p className="grow shrink basis-0 text-[#1B1B1B] text-xs sm:text-base font-normal font-['Poppins'] leading-normal">
                                {userInfo.user?.phone_number_verified !== true ? <>You have to verify your phone number to continue from
                                  here.</> : <>Phone number has been verified.</>}

                              </p>
                            </div>
                          </div>
                        </> : null}

                    </div>


                    {/* save changes button */}

                    {/* <button onClick={() => handleSaveAndContinue("prevent")} class="px-5 py-3 gap-3 rounded-lg bg-[#5843BD] text-base font-medium leading-6 text-center text-white">
                      Save Personal Details
                    </button> */}

                    <p class="text-xs italic font-normal leading-tight text-left text-[#FF6633]">
                      Your data will not be shared with any third party.
                    </p>

                  </div>
                </form>
              </div>

              {/* job interest section */}
              {(p_profile.message == null || p_profile.message?.CompletionPercentage < 95) || window.location.pathname == "/user/profile/update/personalInfo" ?
                (
                  <div className="bg-white py-6 px-4 flex flex-col gap-4 rounded-[16px] resume_page w-full">
                    <p class="text-xl font-semibold leading-8 text-left text-[#5843BD]">
                      Job Interests
                      <span class="font-normal italic leading-8"> (Add 3 job titles)</span>
                    </p>

                    <div className="flex-col justify-start items-start gap-2 flex w-full">
                      <div className="flex gap-3 items-center">
                        <div className="p-[3px] w-1 h-1 rounded-full bg-black"></div>
                        <label
                          className="text-black text-base leading-tight font-medium"
                          for="lastName"
                        >
                          What jobs are you looking for on Moil? <span className="italics font-normal"> (For example, plumber, dry cleaner, electrician)</span>
                        </label>
                      </div>

                      {/* skilss */}
                      <div className="flex items-center flex-col md:flex-row gap-y-2 gap-x-4 justify-between w-full">
                        <TextInput
                          placeholder="1st Job Interest"
                          id="i"
                          name="i"
                          onChangeHandler={(event) =>
                            localStorage.setItem("i", event.target.value)
                          }
                        />

                        <TextInput
                          placeholder="2nd Job Interest"
                          id="j"
                          name="j"
                          onChangeHandler={(event) =>
                            localStorage.setItem("j", event.target.value)
                          }
                        />

                        <TextInput
                          placeholder="3rd Job Interest"
                          id="k"
                          name="k"
                          onChangeHandler={(event) =>
                            localStorage.setItem("k", event.target.value)
                          }
                        />

                      </div>
                    </div>
                  </div>
                ) : null}

              {/* save and continue button */}
              <button
                type="submit"
                className="px-5 py-3 bg-indigo-700 rounded-lg self-end shadow justify-center items-center gap-2.5 flex w-full max-w-[238px]"
                onClick={handleSaveAndContinue}
              >
                {defaults ? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 4V16C18 16.55 17.8043 17.021 17.413 17.413C17.0217 17.805 16.5507 18.0007 16 18H2C1.45 18 0.979333 17.8043 0.588 17.413C0.196667 17.0217 0.000666667 16.5507 0 16V2C0 1.45 0.196 0.979333 0.588 0.588C0.98 0.196667 1.45067 0.000666667 2 0H14L18 4ZM16 4.85L13.15 2H2V16H16V4.85ZM9 15C9.83333 15 10.5417 14.7083 11.125 14.125C11.7083 13.5417 12 12.8333 12 12C12 11.1667 11.7083 10.4583 11.125 9.875C10.5417 9.29167 9.83333 9 9 9C8.16667 9 7.45833 9.29167 6.875 9.875C6.29167 10.4583 6 11.1667 6 12C6 12.8333 6.29167 13.5417 6.875 14.125C7.45833 14.7083 8.16667 15 9 15ZM3 7H12V3H3V7ZM2 4.85V16V2V4.85Z" fill="white" />
                </svg>
                  : <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                    <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                  </svg>
                }

                <span className="text-center text-white text-base font-medium whitespace-nowrap">
                  {defaults ? (
                    "Update and continue"
                  ) : (
                    <>Save and Continue</>
                  )}
                </span>
              </button>

            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      {showModal}
      {verificationModal}
      {savingloader}
      {/* {twenty} */}
    </>
  );
}

// npx -y node-llama-cpp chat --model "hf:Tolugold/moil_tags_extractor/GGUF_FILE" --header "Authorization: Bearer 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" --prompt 'Hi there!'
