import React, { useState, useEffect, useContext } from "react";
import Tab from "../tab/tab";
import TopBar from "../../topbar/Top-Bar";
import SideBar from "../../sidebar/sidebar";
import BasicSelect from "../select/select";
import { TextArea } from "../textarea/textarea";
import Radio from "../radio/radio";
import { useSelector, useDispatch } from "react-redux";
import $, { data } from "jquery";
import {
  generate_professional_summary,
  dispatchJobtitle,
  clearPS,
  uppdateYOF,
  uppdateJobTitle,
  uppdateAbout,
  saveJobAndSum,
  JobtitleDetails,
  dispatchReference,
  dispatchAward,
  dispatchPskills,
  dispatchSkills,
  dispatchLanguage,
  dispatchActivites,
  dispatchEducation,
  personal_information,
  Professional_summary,
  Professional_summary_loading,
  cv_loading,
  summaryUpdateCount,
  spanish_loading,
  lang_type,
  SiteErrorLoading,
  returnHome,
  resume_step_jump_function,
  existing_resume,
  save_secret
} from "../../../Redux/ActionCreator";
import { createPortal } from "react-dom";
import LoaderModal from "../../common/LoaderModal";
import { useParams } from "react-router-dom";
import { Cv_id, generate_my_cv } from "../../../Redux/ActionCreator";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HelmetProvide from "../../common/helmet";
import AddedItem from "../added_item/added_item";
import Bubble from "../bubble";
import { saveIntoServerCache, getTwoFromServerCache, getAllResumeDetailsInCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import { convert } from "../../../Redux/convertResume";
import { baseURL } from "../../../Redux/baseURL";
import SummaryCompleteGamifiedModal from "../../gamified_modals/summary_complete_gamified_confirm_modal/index.jsx";

export default function UserJobType(props) {

  const params = useParams();
  const dispatch = useDispatch();
  const secret = useSelector((prev) => prev.secret);
  const loading_stat = useSelector((load) => load.generateCV);
  const existing_cv_data = useSelector((prev) => prev.existingCV);
  const message = existing_cv_data.message;
  const activity = useSelector((act) => act.activities);
  const skills = useSelector((skills) => skills.skills);
  const title = useSelector((title) => title.jobTitle);
  const t_lang = useSelector((lg) => lg.t_lang);
  const personal_info = useSelector((info) => info.personal_info);
  const education = useSelector((edu) => edu.education);
  const p_skills = useSelector((skills) => skills.p_skills);
  const voluteer = useSelector((vol) => vol.voluntary);
  const award = useSelector((award) => award.award);
  const reference = useSelector((ref) => ref.reference);
  const lang = useSelector((lang) => lang.language);
  const cert = useSelector((cert) => cert.cert);
  const res_lang = useSelector((lang) => lang.lang);
  const profile = useSelector((e) => e.profile);
  const generated = useSelector((e) => e.generateCV);
  const cv = useSelector((prev) => prev.cvId);
  const userInfo = useSelector((e) => e.userInfo);
  const profile1 = useSelector((e) => e.login);
  let stat = profile1.profile_status.profile_status;
  const [t_title, setT_title] = useState("");
  const sp = useSelector((s) => s.spanish);

  const [PsStat, setPsStat] = useState(false);
  const [loading, setLoading] = useState(false);
  // professional summary
  const summary = useSelector((summary) => summary.summary);
  // console.log("summary", summary);
  const p_summary = summary.summary;
  const T_lang = t_lang.selected;
  const [hideModal, setHideModal] = useState(false);
  const [summaryBtnText, setSummaryBtnText] = useState("");
  const [path, setPath] = useState(false);
  let [volunteerCheck, setVolunteerCheck] = useState(false);
  let [awardCheck, setawardCheck] = useState(false);
  let [referenceCheck, setreferenceCheck] = useState(false);
  const [saving, setSaving] = useState(false);
  const [yof, setYOF] = useState("");
  let count = useSelector(e => e.count.count);
  const errors = useSelector(e => e.errors.errMess);
  const sidebar = useSelector(e => e.sidebarOpen.message);

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  let process = searchParams.get("create profile");
  // const [ ninety, setNinety ] = useState(false);

  useEffect(() => {
    if (errors !== null) {
      setStatus(false);
      setTimeout(() => {
        toast.error("Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      dispatch(SiteErrorLoading(true));
    }
  }, [errors]);

  const showModal = () => {
    setHideModal(false);
  };

  // useEffect check for presence of award, reference, and volunteer experience
  useEffect(() => {
    if (award.award !== null && award.award.length !== 0) {
      setawardCheck(true);
    }
    if (reference.ref !== null && reference.ref.length !== 0) {
      setreferenceCheck(true);
    }
    if (voluteer.voluntary !== null && voluteer.voluntary.length !== 0) {
      setVolunteerCheck(true);
    }
  }, [])

  useEffect(() => {
    if (loading_stat.errMess !== null) {
      setTimeout(() => {
        toast.error("Ensure you fill in the necessary information", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      dispatch(cv_loading(true));
    }
  }, [loading_stat.errMess])
  const [execute, setExecute] = useState(false);
  useEffect(() => {
    if (loading_stat.message !== null) {
      if (execute == true) {
        if (Language == "English") {
          handleEnglish();
          setExecute(false);
        } else if (Language == "Spanish") {
          handleSpanish();
          setExecute(false);
        }
      }
    }
  }, [loading_stat.message]);

  useEffect(() => {
    if (summary.errMess !== null) {
      setTimeout(() => {
        toast.error(summary.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      dispatch(Professional_summary_loading(true));
    }
  }, [summary])

  const [twentyModal, setTwentyModal] = useState(false);
  const [fourtyModal, setfourtyModal] = useState(false);
  const [sixtyModal, setsixtyModal] = useState(false);
  const [eightyModal, seteightyModal] = useState(false);
  const [nintyFiveModal, setnintyFiveModal] = useState(false);
  const [hundredModal, sethundredModal] = useState(false);
  const [processModal, setProcessModal] = useState(false);

  useEffect(() => {
    setSaving(false);
    if (profile.message !== null && processModal == true) {
      // if (profile.message?.CompletionPercentage >= 20 && profile.message?.CompletionPercentage < 40) {
      //   setTwentyModal(true)
      // } else if (profile.message?.CompletionPercentage  >= 40 && profile.message?.CompletionPercentage < 60) {
      //   setfourtyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 60 && profile.message?.CompletionPercentage < 80) {
      //   setsixtyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 80 && profile.message?.CompletionPercentage < 95) {
      //   seteightyModal(true);
      // } else if (profile.message?.CompletionPercentage  >= 95 && profile.message?.CompletionPercentage <= 100) {
      //   setnintyFiveModal(true);
      // } else if (profile.message?.CompletionPercentage === 100 && profile.message?.ProfileStatus !== "Completed") {
      //   sethundredModal(true);
      // }
      setnintyFiveModal(true);
    }
  }, [profile.message]);


  const [Language, setLanguage] = useState("");
  useEffect(() => {
    if (window.location.pathname !== "/user/profile/update/about" && process == null && window.location.pathname !== "/form") {
      let SelectedLanguage = getOneFromServerCache("Language");
      SelectedLanguage.then(resp => resp.json())
        .then(resp => {
          // console.log("SelectedLanguage", resp.data);
          if (resp.success == true) {
            if (resp.data == null) {
              setTimeout(() => {
                toast.error("You need to select the language you want your resume to be in.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              saveIntoServerCache("step", 0);
              dispatch(resume_step_jump_function(0));
            } else {
              setLanguage(JSON.parse(resp.data));
            }
          } else if (resp.success === false && resp.message == "Unauthorized") {
            dispatch(returnHome(resp.status));
          }
        }).catch(err => {
          // console.log("Language getting error", err);
        });
    }

    let cacheInfo = getTwoFromServerCache("experience", "summary")
    cacheInfo.then(res => res.json())
      .then(res => {
        // console.log("server cache value", res);
        if (res.success == true) {
          if (res.data.value1 !== null && res.data.value1 !== undefined) {
            let resObj = JSON.parse(res.data.value1);
            // console.log("respObj", resObj);
            dispatch(dispatchActivites(resObj));
          }
          if (res.data.value2 !== null && res.data.value2 !== undefined) {
            let resObj2 = JSON.parse(res.data.value2);
            dispatch(Professional_summary(resObj2));
            $('[name="goal"]').val(resObj2);
          }
          // console.log("Done");
        } else if (res.success === false && res.message == "Unauthorized") {
          dispatch(returnHome(res.status));
        }
      })

    let cacheInfo1 = getTwoFromServerCache("YOF", "title")
    cacheInfo1.then(res => res.json())
      .then(res => {
        // console.log("server cache value", res);
        if (res.success == true) {
          if (res.data.value1 !== null && res.data.value1 !== undefined) {
            let resObj = JSON.parse(res.data.value1);
            // console.log("respObj", resObj);
            $('[name="YOF"]').val(resObj);
          }
          if (res.data.value2 !== null && res.data.value2 !== undefined) {
            let resObj2 = JSON.parse(res.data.value2);
            $('[name="cvtype"]').val(resObj2);
          }
          // console.log("Done");
        } else if (res.success === false && res.message == "Unauthorized") {
          dispatch(returnHome(res.status));
        }
      })

    // if (!window.location.pathname.includes("update")) {
    //   // console.log("was called")
    //   saveIntoServerCache("step", 4);
    // }
    if (education.message == null && skills.skills == null) {
      let resumeDetails = getAllResumeDetailsInCache("personalInfo", "education", "experience", "technicalSkills", "personalSkills", "language", "award", "reference", "YOF", "title", "summary");
      resumeDetails.then(res => res.json())
        .then(res => {
          // console.log("res", res.data)
          let resObj = res.data;
          // console.log("respObj summ", resObj);
          dispatch(personal_information(JSON.parse(resObj.personal)));
          dispatch(dispatchEducation(JSON.parse(resObj.Education)));
          dispatch(dispatchActivites(JSON.parse(resObj.Experience)));
          dispatch(dispatchLanguage(JSON.parse(resObj.Language)));
          dispatch(dispatchSkills(JSON.parse(resObj.Technical_skills)));
          dispatch(dispatchPskills(JSON.parse(resObj.Personal_skills)));
          dispatch(dispatchAward(JSON.parse(resObj.Award)));
          dispatch(dispatchReference(JSON.parse(resObj.Reference)));
          dispatch(Professional_summary(JSON.parse(resObj.Summary)));
          setYOF(JSON.parse(resObj.YOF))
        })
    }
  }, []);

  useEffect(() => {
    if (
      window.location.pathname === "/user/profile/update/about" &&
      profile.message !== null && profile.message?.CompletionPercentage >= 95
    ) {
      setPath(true);
      $('[name="goal"]').val(profile.message.About);
      $('[name="YOF"]').val(profile.message.Years_of_experience);
      // $('[name="cvtype"]').val(profile.message.CvType);
      setT_title(profile.message.Job_type);
      setSummaryBtnText("Update about");
    } else if (window.location.pathname === `/form/${cv.id}/update`) {
      if (generated.message !== null) {
        $('[name="goal"]').val(generated.message?.Professional_summary);
        $('[name="YOF"]').val(generated.message?.year_of_experience);
        $('[name="cvtype"]').val(generated.message?.CvType);
        setT_title(generated.message?.CvType)
      } else {
        $('[name="goal"]').val(summary.summary);
        $('[name="YOF"]').val(yof);
        $('[name="cvtype"]').val(title.jobtitle);
        setT_title(generated.message?.CvType)
      }
      setSummaryBtnText("Generate summary");
    } else {
      if (message !== null) {
        $('[name="YOF"]').val(message?.year_of_experience);
        // $('[name="cvtype"]').val(message?.CvType);
        setT_title(message?.CvType);
        if (summary.summary == null) {
          dispatch(Professional_summary(message?.Professional_summary));
        }
      } else {
        if (message === null) {
          const getExistingCv = async () => {
            fetch(baseURL + "/api/temp/getTempRes", {
              headers: {
                // "Authorization": `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
              credentials: "include"
            }).then(resp => resp.json())
              .then(resp => {
                if (resp.success === true) {
                  // console.log("Temp response", resp);
                  if (resp.data !== null) {
                    dispatch(existing_resume(resp.data));
                    dispatch(Professional_summary(resp.data?.Professional_summary));
                    dispatch(save_secret(resp.data?.Secret))
                  }
                } else if (resp.success === false && resp.message == "Unauthorized") {
                  dispatch(returnHome(resp.status));
                }

              }).catch(err => {
                // console.log("Error getting temp resume", err)
              })
          }
          getExistingCv();
        }
        // console.log("My callllled")
        // console.log("title", title.jobtitle);
        if (title.jobtitle !== null) {
          setT_title(title.jobtitle);
        }
      }
      setSummaryBtnText("Generate summary");
    }

    if (message !== null) {
    }
  }, [])

  React.useEffect(() => {
    // console.log("location", window.location.pathname);

    const filValue = (p_summary) => {
      if (message !== undefined && message !== null) {
        if (summary.summary !== null) {
          $('[name="goal"]').val(p_summary);
        } else {
          $('[name="goal"]').val(message.Professional_summary);
        }
      } else {
        if (summary.summary !== null) {
          $('[name="goal"]').val(p_summary);
        } else {
          $('[name="goal"]').val();
        }
      }
    };

    if (p_summary !== null) {
      filValue(p_summary);
    }
  }, [T_lang, p_summary]);

  // console.log("Technical skill", skills.skills);

  const handleTitle = () => {
    let value = event.target.value;
    setT_title(value);
    dispatch(JobtitleDetails(value));
    saveIntoServerCache("title", value);
  }

  const updateYOF = () => {
    // dispatch YOF
    dispatch(uppdateYOF($('[name="YOF"]').val()));
    $('[name="YOF"]').val("");
    {
      setTimeout(() => {
        toast.success("Year of experience updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    }
  };

  const updateJobT = () => {
    // dispatch job title update.
    dispatch(uppdateJobTitle($('[name="cvtype"]').val()));
    {
      setTimeout(() => {
        toast.success("Job title updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    }
  };

  const generatePS = (arg) => {
    if (window.location.pathname === "/user/profile/update/about" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
      setPsStat(true);
      dispatch(clearPS());
      const cred = {
        Experience: activity.activities,
        Technical_skills: skills.skills,
        Language: Language,
        CvType: profile.message?.CompletionPercentage >= 95 ? t_title : profile.message?.JobInterests[0].value,
        summary: $('[name="goal"]').val()
      };
      console.log(cred.Experience);
      dispatch(generate_professional_summary(cred));
    } else {
      // console.log("count", count)
      if (arg == "update" && count < 3) {
        // reduce the count by 1
        dispatch(summaryUpdateCount());
      }
      // console.log("Technical skill", skills.skills);
      if (t_title == "" && profile.message?.CompletionPercentage >= 95) {
        setTimeout(() => {
          toast.error("Job title is required", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        setPsStat(true);
        dispatch(clearPS());
        const cred = {
          Experience: activity.activities,
          Technical_skills: skills.skills,
          Language: Language,
          CvType: profile.message?.CompletionPercentage >= 95 ? t_title : profile.message?.JobInterests[0].value,
          summary: $('[name="goal"]').val()
        };
        dispatch(dispatchJobtitle($('[name="cvtype"]').val()));
        // console.log("executing professional summary generating process");
        dispatch(generate_professional_summary(cred));
      }
    }
  };

  let text = "Our AI tool is generating a standard professional summary for you. Kindly wait a minute...";
  let text1 = "Resume loading may take a minute.";

  const [text2, setText2] = useState("");
  const [status, setStatus] = useState(false);
  const [a, setA] = useState(false);
  const handleSpanish = async () => {
    if (loading_stat.message !== null) {
      dispatch(spanish_loading(true));
      dispatch(lang_type("English"));
      setStatus(true);
      // console.log("selected ", text);
      let lan = "Latin American Spanish";
      setText2("Spanish")
      const final_arg = { ...loading_stat.message, Lang: [{ lang: lan }] };
      let flag = "resume";
      let route = profile.message !== null || profile.message?.CompletionPercentage >= 95 ? "/allCv" : `/user/profile/:${userInfo.user.profile_id}`;
      dispatch(convert(final_arg, flag, route, 0));
    } else {
      setTimeout(() => {
        toast.error(
          "No information to translate.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }, 50);
    }
  };

  const handleEnglish = async () => {
    if (loading_stat.message !== null) {
      // lets handle the entire resume in English
      dispatch(spanish_loading(true));
      dispatch(lang_type("Spanish"));
      setStatus(true);
      // console.log("selected", text);
      let lan = "English";
      setText2("English")
      const final_arg = { ...loading_stat.message, Lang: [{ lang: lan }] };
      let flag = "resume";
      let route = profile.message !== null || profile.message?.CompletionPercentage >= 95 ? "/allCv" : `/user/profile/:${userInfo.user.profile_id}`;
      dispatch(convert(final_arg, flag, route, 0));
    } else {
      setTimeout(() => {
        toast.error(
          "No information to translate.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }, 50);
    }
  }
  const handleSaveAndPreview = () => {
    if (window.location.pathname === "/user/profile/update/about" && profile.message !== null && profile.message?.CompletionPercentage >= 95) {
      dispatch(uppdateAbout($('[name="goal"]').val()));
      dispatch(uppdateYOF($('[name="YOF"]').val()));
      dispatch(uppdateJobTitle($('[name="cvtype"]').val()));
      navigate(`/user/profile/:${userInfo.user.profile_id}`);
    } else {
      if ($('[name="YOF"]').val().length > 2) {
        setTimeout(() => {
          toast.error("Year of experience cannot be more that 2 digit", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else if ((t_title == "" || $('[name="cvtype"]').val() == "") && profile.message?.CompletionPercentage >= 95) {
        setTimeout(() => {
          toast.error("Job title cannot be empty", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      } else {
        let edu;
        if (education.education !== null && education.education !== undefined) {
          edu = education.education;
        } else {
          edu = [];
        }
        // console.log("edu", edu);

        let awd;
        if (award.award === "" && award.award.length === 0) {
          awd = [];
        } else {
          awd = award.award;
        }

        let credentials;
        // console.log("YOF", $('[name="YOF"]').val())
        if (profile.message !== null && profile.message?.CompletionPercentage >= 95) {
          credentials = {
            Fullname:
              personal_info.message.Firstname +
              " " +
              personal_info.message.LastName,
            FirstName: personal_info.message?.Firstname,
            LastName: personal_info.message?.LastName,
            Email: personal_info.message.Email,
            Phone_number: personal_info.message.Phone_number,
            Address: personal_info.message.Address,
            Professional_summary:
              summary.summary !== null ? summary.summary :
                $('[name="goal"]').val(),
            Education: edu,
            Experience: activity.activities,
            Technical_skills: skills.skills, //data.get('technical_skills'),
            Personal_skills: p_skills.p_skills, //data.get('personal_skills'),
            Volunteer: voluteer.voluntary,
            Reference: reference.ref || [],
            Language: lang.lang, //data.get('language'),
            CvType: t_title,
            Lang: [Language],
            Award: awd,
            year_of_experience: $('[name="YOF"]').val(),
          };
          // saveIntoServerCache("summaryAndTitle", value);
          // console.log("credentials", credentials);
          dispatch(Cv_id(params));
          if (Object.keys(credentials).length == 0) {
            // show an error message        
            setTimeout(() => {
              toast.error("Ensure you fill in the necessary information", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          } else {
            if (summary.summary == null || $('[name="goal"]').val() == "") {
              // throw an error message          
              setTimeout(() => {
                toast.error('Click the "generate summary" button to generate a professional summary for your resume', {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
            } else {
              saveIntoServerCache("step", 0);
              setHideModal(true);
              setLoading(true);
              // console.log("Got here")
              setExecute(true);
              dispatch(generate_my_cv(credentials));

            }
          }
        } else {
          if (profile.message == null || (profile.message?.CompletionPercentage !== undefined && profile.message?.CompletionPercentage < 95) || process !== null) {
            setSaving(true);
          }
          let sum;
          if (summary.summary !== null) {
            sum = summary.summary;
          } else {
            sum = $('[name="goal"]').val();
          }
          if (profile.message == null || (profile.message?.CompletionPercentage !== undefined && profile.message?.CompletionPercentage < 95) || process !== null) {
            let YOF = $('[name="YOF"]').val();
            dispatch(saveJobAndSum(profile.message?.JobInterests[0].value, sum, YOF, secret.secret));
            // setNinety(true);
            setProcessModal(true)
          }
        }
      }
    }
  };

  // console.log("t_title", t_title)
  // console.log("setExecute", execute)

  const handleSummaryChange = (e) => {
    e.preventDefault();
    dispatch(Professional_summary(e.target.value));
    saveIntoServerCache("summary", e.target.value);
  }

  // const twenty = twentyModal ? <TwentyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const fourty = fourtyModal ? <FortyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const sixty = sixtyModal ? <SixtyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  // const eighty = eightyModal ? <EightyPercentConfirmationGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  const nintyFive = nintyFiveModal ? <SummaryCompleteGamifiedModal profileLink={`/user/profile/:${userInfo.user.profile_id}`} handleStepChange={props.handleStepChange} /> : null;
  const savingloader = saving ? <LoaderModal text="Saving your information" /> : null;


  // let Acknowledge = ninety ? <NinetyFivePercentConfirmationGamifiedModal  profileLink={`/user/profile/:${userInfo.user.profile_id}`} /> : null;
  loading_stat

  const handlePreviousBTN = () => {
    saveIntoServerCache("step", 0)
    if (window.location.pathname === "/user/profile/update/about" && profile.message !== null && profile.message?.CompletionPercentage < 95) {
      navigate("/user/profile/update/skills")
    } else {
      props.handleStepChangeBack();
    }
  }
  return (
    <>
      <HelmetProvide
        title="Moil | Create Your Resume"
        description="Build your professional resume with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
        className="notranslate"
      />
      <div className={`grid grid-cols-auto md:grid-cols-smdash ${sidebar ? 'cont:grid-cols-dash' : ''} grid-rows-dash`}>
        <TopBar topBarShowMenu={true} />
        <SideBar />
        <div className="bg-[#FAFAFA] px-3 lg:pl-6 py-8 w-full overflow-y-scroll ScrollOnly h-calc-70 flex justify-center">
          <div className="w-full xtraxl:max-w-calculate flex flex-col items-center">
            {/* {loading_stat.message !== null ? (
              <div>
                {hideModal &&
                  createPortal(
                    <PaymentMethodModal hideModal={showModal} />,
                    document.querySelector("#modal")
                  )}
              </div>
            ) : null} */}
            <p class="max-w-[900px] xtraxl:max-w-calculate w-full text-[24px] font-semibold leading-9 text-left text-black text-opacity-87">
              Complete your profile
            </p>
            <div className="max-w-[900px] xtraxl:max-w-calculate w-full py-5 rounded-2xl flex-col justify-start items-start gap-5 inline-flex">
              <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0 max-w-[calc(100vw-88px)] overflow-hidden">
                {/* <Tab
                  // label="Personal Information"
                  label="Personal Information"
                  current={false}
                  completed={true}
                  num="1"
                /> */}
                <Tab num="2" current={false} label="Experience" completed={true} />
                <Tab num="3" current={false} label="Education" completed={true} />
                <Tab num="4" current={false} label="Skill/Language" completed={true} />
                <Tab num="5" current={true} label="Professional Summary" completed={false} />
              </div>

              <p class="text-base font-medium leading-6 text-left text-[#0D0D0D]">
                Let employers know the type of job you want to be hired for.<br />
                We have pre-filled your job from the personal information you supplied.
              </p>
              <div className="flex-col justify-start items-start gap-4 flex w-full rounded-[16px] bg-white resume_page py-6 px-4 ">

                <p class="text-xl font-semibold leading-8 text-left text-[#5843BD]">
                  Job and professional summary
                </p>

                <div className="flex flex-col gap-y-4">
                  <p className="text-red-500">Before continuing, you can add any of the following options to your resume: Awards, etc</p>
                  {/* <div className="flex gap-x-5 flex-wrap gap-y-3">
                    <Bubble item="Volunteer Experience" checked={volunteerCheck} click={() => props.handlejump(6)} />
                    <Bubble item="Award" checked={awardCheck} click={() => props.handlejump(7)} />
                    <Bubble item="Reference" checked={referenceCheck} click={() => props.handlejump(5)} />
                    <input type="checkbox" name="volunteer" /><label>Volunteer</label>
                  </div> */}

                </div>
                <div className="flex-col justify-start items-start gap-4 flex w-full">
                  <div className="flex-col justify-start items-start gap-3 flex w-full">
                    {
                      profile.message?.CompletionPercentage >= 95 ?
                        <>
                          <div className="flex gap-3 items-center">
                            <div className="w-2 h-2 rounded-full bg-black"></div>
                            <label
                              className="text-black text-base font-medium"
                              for="jobType"
                            >
                              What type of job are you looking for?
                            </label>
                          </div>
                          <p className="text-orange-500 text-xs font-medium">
                            Let employers know the type of job you want to be hired for. For example, plumbing, waitress, electrician.
                          </p>
                          <input
                            type="text"
                            className="p-4 w-full rounded-lg border border-indigo-400 border-opacity-30"
                            placeholder="Job title"
                            id="jobType"
                            name="cvtype"
                            value={t_title}
                            onChange={handleTitle}
                          />
                        </> : null
                    }
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="w-2 h-2 rounded-full bg-black"></div>
                      <label
                        className="text-black text-base font-medium"
                        for="firstName"
                      >
                        How many years of experience do you have?
                      </label>
                    </div>
                    <input
                      type="number"
                      className="p-4 w-full rounded-lg border border-indigo-400 border-opacity-30"
                      placeholder="Enter years of experience here"
                      id="school"
                      name="YOF"
                      min="1"
                      max="99"
                      onChange={(e) => saveIntoServerCache("YOF", e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-y-3">
                    {window.location.pathname == "/user/profile/update/about" ? <p className="text-orange-500 text-base font-normal w-full">Edit and save</p> : <p className="text-orange-500 text-base font-normal w-full">
                      Describe your professional summary OR click on the ‘Generate Summary’<br /> button so that our AI can help you with a fitting summary.
                    </p>}
                    <button
                      className="w-max px-5 py-2.5 bg-orange-500 rounded-3xl border justify-start items-start gap-2 inline-flex"
                      onClick={() => generatePS("first")}
                    >
                      <span className="text-center text-white text-base font-medium">
                        {summaryBtnText}
                      </span>
                    </button>
                  </div>
                  <div className="flex-col justify-start items-start gap-4 flex w-full">
                    <div className="flex gap-3 items-center">
                      {/* <div className="px-1 w-2 h-2 rounded-full bg-black"></div> */}
                      {/* <label
                      className="text-black text-base font-medium"
                      for="professionalSummary"
                    >
                      {language["pro_summary"]}
                    </label> */}
                    </div>
                    {/* <p className="text-orange-500 text-xs font-medium w-full">
                    {language["describe_pro_sum"]}
                  </p> */}
                    <TextArea
                      placeholder="0/500"
                      id="professionalSummary"
                      name="goal"
                      onChangeHandler={handleSummaryChange}
                    />
                  </div>
                  {path == false ?
                    <>
                      {count == 3 ? null :
                        <>
                          <p className="text-red-500 mt-[-15px]">If you add or edited any text, click "update" to update professional Summary{/* Add or edit the summary text and click the update button for a final version of the summary. */}</p>
                          <button
                            className="px-5 py-2.5 bg-orange-500 rounded-3xl border justify-start items-start gap-2 inline-flex"
                            onClick={() => generatePS("update")}
                          >
                            <span className="text-center text-white text-base font-medium">
                              update
                            </span>
                          </button>
                        </>}
                    </> : null
                  }
                </div>
              </div>
              <div className="self-stretch justify-between items-start gap-4 inline-flex">
                <button
                  className="pr-2 md:pr-0 md:px-5 py-3 rounded-lg self-end justify-center items-center gap-1 md:gap-2.5 flex w-max max-w-[238px]"
                  onClick={handlePreviousBTN}

                >
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8L17.43 9.393L11.85 15H24V17H11.85L17.43 22.573L16 24L8 16L16 8Z" fill="#FF6633" />
                    <path d="M16 30C18.7689 30 21.4757 29.1789 23.778 27.6406C26.0803 26.1022 27.8747 23.9157 28.9343 21.3576C29.9939 18.7994 30.2712 15.9845 29.731 13.2687C29.1908 10.553 27.8574 8.05845 25.8995 6.10051C23.9416 4.14258 21.447 2.80921 18.7313 2.26901C16.0155 1.72882 13.2006 2.00607 10.6424 3.06569C8.08426 4.12532 5.89776 5.91973 4.35942 8.22202C2.82108 10.5243 1.99999 13.2311 1.99999 16C2.00423 19.7117 3.48059 23.2702 6.10518 25.8948C8.72977 28.5194 12.2883 29.9958 16 30ZM16 4.00001C18.3734 4.00001 20.6934 4.70379 22.6668 6.02237C24.6402 7.34095 26.1783 9.21509 27.0865 11.4078C27.9948 13.6005 28.2324 16.0133 27.7694 18.3411C27.3064 20.6689 26.1635 22.8071 24.4853 24.4853C22.807 26.1635 20.6688 27.3064 18.3411 27.7694C16.0133 28.2325 13.6005 27.9948 11.4078 27.0866C9.21508 26.1783 7.34094 24.6402 6.02236 22.6668C4.70378 20.6935 3.99999 18.3734 3.99999 16C4.0037 12.8185 5.26917 9.76845 7.5188 7.51882C9.76844 5.26918 12.8185 4.00371 16 4.00001Z" fill="#FF6633" />
                  </svg>


                  <span className="text-center text-[#FF6633] text-sm md:text-base font-medium whitespace-nowrap">
                    Previous
                  </span>
                </button>

                <button
                  className="px-3 md:px-5 py-3 bg-indigo-700 rounded-lg self-end shadow justify-center items-center gap-2 md:gap-2.5 flex w-max max-w-[238px]"
                  onClick={handleSaveAndPreview}

                >
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                    <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                  </svg>


                  <span className="text-center text-white text-sm md:text-base font-medium whitespace-nowrap">
                    Save and Preview
                  </span>
                </button>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
        <ToastContainer />
        {status === true ? (
          sp.spanish !== null ? (
            <>{() => (setStatus(true), setA(!a))}</>
          ) : (
            <LoaderModal text={`Processing your information`} />
          )
        ) : null}
        {loading === false ? null : (
          <div>
            {loading_stat.message === null && loading_stat.errMess === null ? (
              <LoaderModal text={text} />
            ) : null}
          </div>
        )}
        {loading === true ? (
          <div>
            {loading_stat.message === null && loading_stat.errMess === null ? (
              <LoaderModal text={text1} />
            ) : null}
          </div>
        ) : null}
        {PsStat === true ? (
          summary.summary !== null ? null : (
            <div>
              <LoaderModal text={text} />
            </div>
          )
        ) : null}
        {/* {Acknowledge} */}
        {/* {twenty}
        {fourty}
        {sixty}
        {eighty} */}
        {nintyFive}
        {savingloader}
      </div>
    </>
  );
}
